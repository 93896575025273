'use client';

import { Metadata } from 'next';
import { ErrorAlert } from '@/components/Shared/ErrorAlert';
export const metadata: Metadata = {
  title: 'Error | Smart Data Hub',
  description: 'Unexpected error.',
  openGraph: {
    title: 'Error',
    description: 'Unexpected error.'
  },
  robots: 'index, follow'
};
export default function ErrorPage() {
  return <div className="flex flex-col container py-10 px-5 gap-5">
      <h1 className="text-secondary-readable mb-0">
        Internal server error <span className="font-mono">(500)</span>
      </h1>

      <ErrorAlert />
    </div>;
}